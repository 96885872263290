import React from 'react'
import { Link, graphql } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../components/layoutV2'
import SEO from '../components/seo'
import LayoutInner from '../components/layoutInnerV2'
import { Grid, Hidden } from '@material-ui/core'
import * as PATHS from '../utils/paths'
import {GridPageHero, SectionContainer} from "../components/GridStuff";

const serviceSnippetStyles = theme => ({
    root: {
        marginBottom: 32
    },
    image: {
        marginBottom: 0,
        maxWidth: '100%'
    },
    titleText: {
        marginBottom: 4,
        lineHeight: '24px',
        fontSize: 20
    },
    descriptionText: {
        lineHeight: '24px',
        fontSize: 16,
        marginBottom: 0
    },
    workSampleList: {
        fontSize: 14,
        paddingLeft: 24,
        marginBottom: 0
        // lineHeight: "16px"
    }
})

const _ServiceSnippet = ({ id, title, children, imagePath, workSamples = [], classes }) => {

    return (

        <Grid container className={classes.root}
              id={id}
              spacing={4} direction={'row'}>

            <Hidden smUp>
                <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                    {imagePath && <img className={classes.image} src={imagePath}/>}
                </Grid>
            </Hidden>


            <Grid item xs={12} sm={7}>
                <header>
                    <h3 className={classes.titleText}>

                        {title}

                    </h3>

                </header>
                <section>
                    <p className={classes.descriptionText}>
                        {children}
                    </p>
                    {0 < workSamples.length && (
                        <ul className={classes.workSampleList}>
                            {workSamples.map(({ path, label }) => (
                                <li key={path}><Link to={path}>{label}</Link></li>
                            ))}
                        </ul>
                    )}
                </section>
            </Grid>

            <Hidden xsDown>
                <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                    {imagePath && <img className={classes.image} src={imagePath}/>}
                </Grid>
            </Hidden>
        </Grid>)
}

const ServiceSnippet = withStyles(serviceSnippetStyles())(_ServiceSnippet)

const styles = theme => ({
    root: {
        "& p": {
            // color: "#ff0000",
            fontFamily: "itext",
            fontSize: 21,
            lineHeight: 1.48,
            marginBottom: 24,
            hyphens: "manual",
        },
        "& li": {
            // color: "#ff0000",
            fontFamily: "itext",
            fontSize: 21,
            lineHeight: 1.48,
            marginBottom: 12,
            hyphens: "manual",
        }
    },
    spacer: {
        height: 96,
    },
})

const _ServiceLink = withStyles(styles)(({ label, path, classes }) => {

    return (<a href={path}>{label}</a>)
})


const _Page = ({ data, location, classes }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={'3iap Services'} location={{ pathname: PATHS.SERVICES_PAGE }}/>

            <LayoutInner className={classes.root}>

                <GridPageHero
                    imgURLVertical={"https://3iap.com/cdn/images/3iap-services-head-vert-v01.png"}
                    imgURLHorizontal={"https://3iap.com/cdn/images/3iap-services-head-hor-v01.png"}>
                    <h1>Services</h1>
                    <p>
                        3iap's services are outcome-driven and deeply rooted in the latest design and visualization
                        research.
                    </p>
                    <p>
                        Specializing in the intersection of social psychology and visualization design allows us to bring
                        a unique perspective on the ways that data can both reflect and influence people's attitudes,
                        beliefs,
                        emotions, and behaviors.
                        At the same time, technical, analytical, and research expertise allow us to find incisive stories in
                        even the
                        most complex datasets.
                    </p>

                    <p>
                        This makes 3iap an ideal partner for impact-oriented clients, including philanthropic foundations,
                        public health institutions, as well as corporate, government, and non-profit groups involved in
                        evaluating
                        programs for social impact, such as offices of Diversity, Equity, and Inclusion (DEI), Environmental
                        Health and Safety (EHS), or
                        Environmental, Social, and Governance (ESG).
                    </p>

                </GridPageHero>

                <div className={classes.spacer}/>

                <SectionContainer id="data-visualization" title={"Custom Data Visualization Design"}>
                    <p>
                        How might we make your data’s story unignorable?

                        Data already speaks effectively to the rational parts of our brains,
                        but to persuade audiences and get your message to stick,
                        the story also needs to connect emotionally and experientially.
                        At the same time, responsible design requires conscious consideration
                        of how visualizations impact not only viewers, but the people being visualized,
                        particularly when visualizing outcomes related to marginalized communities.
                        <br/><br/>
                        For cases with especially complex data, that
                        needs to resonate with non-data-savvy audiences,
                        or just needs to look and feel compelling,
                        then a solid design process and a deep technical toolkit
                        are the trusted path forward.
                    </p>
                    <ul>
                        {[
                            {
                                label: 'Timely Advice: How Long Does Dataviz Take?',
                                path: '/timely-advice-how-long-does-dataviz-take-auC2KawtRB2Gvy2IMHPwLA/'
                            },
                            {
                                label: 'Nightingale Magazine: Absurd Print Editorial Visualizations',
                                path: '/work/nightingale-magazine-print-editorial-explanatory-visualizations/'
                            },
                            {
                                label: 'What do data visualization consultants do?',
                                path: '/data-visualization-consulting'
                            },
                            {
                                label: 'World Government Summit: Twenty-Nine Years of Hot Air',
                                path: '/work/world-government-summit-hot-air-global-emissions-dataviz-poster/'
                            },
                            {
                                label: 'Clinical Trials Dataviz Design',
                                path: '/work/pharmaceutical-clinical-trials-efficacy-data-visualization-design-development/'
                            },
                            {
                                label: 'LawAtlas: Product & Dataviz Design',
                                path: '/work/lawatlas-temple-academic-open-dataset-visualization-design/'
                            }
                        ].map(({label, path}, index) => {
                            return (<li>
                                <_ServiceLink label={label} path={path} key={index}/>
                            </li>)
                        })}

                        {/*<li><_ServiceLink */}
                        {/*    */}
                        {/*</li>*/}
                    </ul>


                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer id="analysis-reporting" title={"Analysis & Reporting"}>
                    <p>
                        You can always hear a groan in the room when someone suggests a new dashboard.
                        We’re all understandably wary of information overload:
                        Eighty page reports with too many numbers, none of which matter.
                        This is a quick way to disengage viewers.

                        To change behaviors, impact policy or drive decisions,
                        viewers need to be able to engage with the data.
                        This requires:

                        1) understanding & prioritizing data from viewers’ perspectives,
                        2) trustworthy metrics and analysis,
                        3) structuring and visualizing insights so they’re comprehensible and compelling,
                        4) thoughtful automation to reliably handle large-scale insights.
                    </p>
                    <ul>
                        {[
                            {
                                label: 'People Analytics Reporting Design',
                                path: '/work/workplace-people-analytics-dataviz-report-design/'
                            },
                            {
                                label: 'Ugandan Survey Research: Data Exploration Tool Design',
                                path: '/work/survey-research-data-exploration-product-design/'
                            },
                            {
                                label: 'Good Dashboards Inform. Great Dashboards Align.',
                                path: '/visualizing-team-performance-6FFZp4xeTZGcQpi2fVkl2A/'
                            },
                            {
                                label: 'Goal Setting: “Measurable” shouldn\'t mean "Miserable"',
                                path: '/measurable-okrs-dont-have-to-be-miserable-ZSPhW1q8RuyyOHfjKsSg8w/'
                            },


                        ].map(({label, path}, index) => {
                            return (<li>
                                <_ServiceLink label={label} path={path} key={index}/>
                            </li>)
                        })}

                        {/*<li><_ServiceLink */}
                        {/*    */}
                        {/*</li>*/}
                    </ul>


                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer id={"data-product-design-development"} title={"Data Product Design & Development"}>
                    <p>
                        Data products are different. Not only do you have the same product-market-fit challenges as a
                        traditional product, data products bring the added complexity of developing the dataset and
                        designing for non-deterministic UX. 3iap has deep experience in coupling systems thinking with the
                        lean design approach used by the world’s leading product makers.
                    </p>
                    <ul>
                        {[
                            {
                                label: 'StreetLinx: MVP Design & Development',
                                path: '/work/streetlinx-data-product-design-development/'
                            },
                            {
                                label: 'Ugandan Survey Research: Data Exploration Tool Design',
                                path: '/work/survey-research-data-exploration-product-design/'
                            },
                            {
                                label: 'PwC PerformPlus: Product Design, Strategy, Delivery',
                                path: '/work/pwc-perform-data-product-design-analytics-consulting/'
                            },
                            {
                                label: 'LawAtlas: Product & Dataviz Design',
                                path: '/work/lawatlas-temple-academic-open-dataset-visualization-design/'
                            },
                            {
                                label: 'Notch.me: Product Design, Dataviz, Delivery',
                                path: '/work/consumer-health-tracking-data-visualization-design/'
                            },
                            {
                                label: 'What are data visualization apps?',
                                path: '/data-visualization-apps'
                            },
                        ].map(({label, path}, index) => {
                            return (<li>
                                <_ServiceLink label={label} path={path} key={index}/>
                            </li>)
                        })}

                        {/*<li><_ServiceLink */}
                        {/*    */}
                        {/*</li>*/}
                    </ul>
                </SectionContainer>


                <div className={classes.spacer}/>

                <SectionContainer title={"Data Capture & Engineering"}>
                    <p>
                        Life is easy when data comes through an API. But sometimes data capture requires a bit of
                        creativity. Maybe you build a fun game to coax volunteers into labeling training data? Perhaps it’s
                        crowd-sourcing on mechanical turk? Or scraping yelp & seamless records for every restaurant in
                        NYC...
                    </p>
                    <ul>
                        {[
                            {
                                label: 'Doom Haikus: Data Engineering & Dataset Development',
                                path: '/work/doom-haikus-ml-data-engineering-product-prototyping/'
                            },
                            {
                                label: 'Dispersion & Disparity: Mechanical Turk Research',
                                path: '/dispersion-disparity-equity-centered-data-visualization-research-project-Wi-58RCVQNSz6ypjoIoqOQ/'
                            },
                            {
                                label: 'Prickly Co: Pattern Rendering Pipeline',
                                path: '/work/prickly-pattern-automated-3d-graphics-rendering/'
                            },
                        ].map(({label, path}, index) => {
                            return (<li>
                                <_ServiceLink label={label} path={path} key={index}/>
                            </li>)
                        })}

                        {/*<li><_ServiceLink */}
                        {/*    */}
                        {/*</li>*/}
                    </ul>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={"Design Research & Discovery"}>
                    <p>
                        Every data story, tool, product or service starts as an ephemeral hunch.
                        A little voice in the back of your mind says “Hey, the world might be better if ...”
                        The challenge is getting the idea out of your head (and your users’ heads) and into a concrete form the team can rally around.
                        This starts with 3 questions: Who is our audience? What are we trying to accomplish for them (and ourselves)? What does the data say?


                    </p>
                    <ul>
                        {[
                            {
                                label: 'StreetLinx: MVP Design & Development',
                                path: '/work/streetlinx-data-product-design-development/'
                            },
                            {
                                label: 'PwC PerformPlus: Product Design, Strategy, Delivery',
                                path: '/work/pwc-perform-data-product-design-analytics-consulting/'
                            },
                            {
                                label: 'Notch.me: Product Design, Dataviz, Delivery',
                                path: '/work/consumer-health-tracking-data-visualization-design/'
                            }
                        ].map(({label, path}, index) => {
                            return (<li>
                                <_ServiceLink label={label} path={path} key={index}/>
                            </li>)
                        })}

                        {/*<li><_ServiceLink */}
                        {/*    */}
                        {/*</li>*/}
                    </ul>
                </SectionContainer>

            </LayoutInner>
        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

import {withStyles} from "@material-ui/core/styles";
import React from "react";

const MARGIN_SIZE = 24

const SMALL_DOWN_MEDIA_QUERY = "@media (max-width: 880px)"
const XS_DOWN_MEDIA_QUERY = "@media (max-width: 640px)"

const MAIN_CONTENT_MAX_WIDTH = 640
const MAIN_CONTENT_MIN_WIDTH = 560

const gridStyles = theme => ({
    gridContainer: {
        display: "flex",
        marginLeft: -MARGIN_SIZE,
        alignItems: "stretch",
        marginBottom: -MARGIN_SIZE,
        flexWrap: "wrap",
        // rowGap: MARGIN_SIZE,

        [SMALL_DOWN_MEDIA_QUERY]: {
            flexDirection: "column",
            alignItems: "center",
            // marginLeft: 0,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            marginLeft: 0,
        }

    },
    gridColFlex: {

        flex: "1 1 20%",
        paddingLeft: MARGIN_SIZE,
        paddingBottom: MARGIN_SIZE,
        maxWidth: "50%",
        minWidth: 182,

        [SMALL_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: MAIN_CONTENT_MAX_WIDTH + 1* MARGIN_SIZE,
            minWidth: MAIN_CONTENT_MIN_WIDTH + 1*MARGIN_SIZE,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
            paddingBottom: 0,
        },


    },
    gridColMainContent: {
        // width: 636+48,

        flex: "1 1 60%",
        paddingLeft: 2*MARGIN_SIZE,
        paddingBottom: MARGIN_SIZE,
        maxWidth: MAIN_CONTENT_MAX_WIDTH + 2* MARGIN_SIZE,
        minWidth: MAIN_CONTENT_MIN_WIDTH + 2*MARGIN_SIZE,

        [SMALL_DOWN_MEDIA_QUERY]: {
            paddingLeft: 1*MARGIN_SIZE,
            flex: "0 0 auto",
            width: "100%",
            maxWidth: MAIN_CONTENT_MAX_WIDTH + 1* MARGIN_SIZE,
            minWidth: MAIN_CONTENT_MIN_WIDTH + 1*MARGIN_SIZE,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
            paddingBottom: 0,
        },

    },
    gridColQuarter: {
        maxWidth: (MAIN_CONTENT_MAX_WIDTH-MARGIN_SIZE)*0.5+MARGIN_SIZE,
        minWidth: (MAIN_CONTENT_MIN_WIDTH-MARGIN_SIZE)*0.5+MARGIN_SIZE,
        flex: "1 1 30%",
        paddingLeft: MARGIN_SIZE,
        paddingBottom: MARGIN_SIZE,

        [SMALL_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: MAIN_CONTENT_MAX_WIDTH + 1* MARGIN_SIZE,
            minWidth: MAIN_CONTENT_MIN_WIDTH + 1*MARGIN_SIZE,
            paddingLeft: 4*MARGIN_SIZE,
            paddingBottom: MARGIN_SIZE,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
        },

    },
    headImgWrap: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        aspectRatio: 1.618,
    },
    headImgHor: {
        display: "none",
        [SMALL_DOWN_MEDIA_QUERY]: {
            display: "block"
        },
    },
    headImgVert: {
        display: "block",
        [SMALL_DOWN_MEDIA_QUERY]: {
            display: "none"
        },
    },
});


export const GridContainer = withStyles(gridStyles)(({children, classes, style, id}) => {
    return (
        <div className={classes.gridContainer} style={style} id={id}>
            {children}
        </div>
    )
})


export const GridColMainContent = withStyles(gridStyles)(({children, classes}) => {
    return (
        <div className={classes.gridColMainContent}>
            {children}
        </div>
    )
})


export const GridColLeftContent = withStyles(gridStyles)(({children, classes}) => {
    return (
        <div className={classes.gridColFlex}>
            {children}
        </div>
    )
})


export const GridColQuarterContent = withStyles(gridStyles)(({children, classes, style}) => {
    return (
        <div className={classes.gridColQuarter} style={style}>
            {children}
        </div>
    )
})


export const GridPageHero = withStyles(gridStyles)(({
                                                        children,
                                                        classes,
                                                        style,
                                                        imgURLVertical,
                                                        imgURLHorizontal
}) => {
    return (


    <GridContainer style={style}>
    <GridColLeftContent>
    <div className={classes.headImgWrap}>
        <img className={classes.headImgVert} src={imgURLVertical}/>
        <img className={classes.headImgHor} src={imgURLHorizontal}/>
    <br/><br/>


</div>
</GridColLeftContent>

<GridColMainContent>

    {children}

</GridColMainContent>



</GridContainer>
    )
})


export const SectionContainer = ({title, children, id}) => {
    return (
        <GridContainer id={id}>
            <GridColLeftContent>
                <h2>{title}</h2>
            </GridColLeftContent>
            <GridColMainContent>


                {children}
            </GridColMainContent>

        </GridContainer>
    )
}